import React from 'react'
import {Link} from "react-router-dom";
import logo from "../images/LOGO 02 JPG.jpg";

import "./Navbar.css"
function Navbar() {
  return (
    <div>
      <div className='fixed-top'>

      <div className="traditionaljagadhatripuja">
          <div className="album py-1">

            <div className="container">

              <div className="row ">

                <div className="col-md-5" >
                  <div className="d-flex justify-content-between align-items-center">

                    <a style={{color:"white"}} className="btn-gradnav"  href="tel: +91 9932913185" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-minus" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5"/>
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg> 9932913185 / <a style={{color:"white"}} href='tel: 7908867711'> 7908867711 </a></a>

                  <a style={{color:"white"}}  href='https://www.facebook.com/prabhat.sangha.56'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
</svg> Facebook</a>



                  </div>

                </div>

                <div className="d-flex justify-content-between align-items-center">





                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
    
    <Link to="/">
    <img src={logo}  height="50"  width="150" alt=''/>
    </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/about-us" className="nav-link" href="#">About us</Link>
        </li>
        <li className="nav-item">
          <Link to="/Hon'ble-Member" className="nav-link" href="#">Member</Link>
        </li>
        <li className="nav-item">
          <Link to="/Award" className="nav-link" href="#">Award Distribute</Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link" href="#">Our Achivement</Link>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Charitable Trust Active
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item" href="Social-work">Social Work</a></li>
            <li><a className="dropdown-item" href="">Festival</a></li>
            <li><a className="dropdown-item" href="">Sport Activity Photo</a></li>
            <li><a className="dropdown-item" href="#">Others Activity</a></li>
       
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/Contact-us" className="nav-link" href="#">Contact us</Link>
        </li>
      </ul>
     <a href='tel: 7908867711'>
        <div  className="btn-grad56" type="submit">Helpline Number</div>
        </a>
      
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar