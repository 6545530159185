
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyBUrUoM7i9AZ_cjSsOoIL3WvzpLfrK6j0c",
  authDomain: "krishnagar-prabhatsangha.firebaseapp.com",
  projectId: "krishnagar-prabhatsangha",
  storageBucket: "krishnagar-prabhatsangha.firebasestorage.app",
  messagingSenderId: "234321722071",
  appId: "1:234321722071:web:710aed85f101e06b304e92",
  measurementId: "G-YEG2QWRZ6P"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;