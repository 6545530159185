import React from 'react'
import Navbar from '../inc/Navbar'
import Designation from './Designation'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function Member() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="maroon"/>
      
            <div className="album py-3">

<div className="container">
    <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">CORE COMMITTEE</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">OTHERS COMMITTEE</button>

        </div>
    </nav>
    <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <br/>
        <Designation/>
        </div>
        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <br/>
            
            Others Committee
        </div>
    </div>
</div>
</div>
        
        <br/>
        <br/>
    </div>
  )
}

export default Member