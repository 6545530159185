import React from 'react'
import Award from './Award'
import Navbar from '../inc/Navbar'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function Awardpage() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="maroon" />
            <div className="krishnagarjagadhatritraditionalpuja">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Award Distribute</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
   
            <Award />

            <br />
            <br />
        </div>
    )
}

export default Awardpage